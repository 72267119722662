:root {
  --black: hsl(0, 0%, 4%);
  --black-bis: hsl(0, 0%, 7%);
  --black-ter: hsl(0, 0%, 14%);
  --grey-darker: hsl(0, 0%, 21%);
  --grey-dark: hsl(0, 0%, 29%);
  --grey: hsl(0, 0%, 48%);
  --grey-light: hsl(0, 0%, 71%);
  --grey-lighter: hsl(0, 0%, 86%);
  --white-ter: hsl(0, 0%, 96%);
  --white-bis: hsl(0, 0%, 98%);
  --white: hsl(0, 0%, 100%);
  --orange: #eb9532;
  --yellow: #f5d76e;
  --green: #1bbc9b;
  --turquoise: #36d7b7;
  --blue: #19b5fe;
  --purple: #aea8d3;
  --red: #d24d57;

  --font-primary: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial',
  sans-serif;
  --font-secondary: 'Fira Sans', sans-serif;
  --font-monospace: 'Inconsolata', monospace;
}

* {
  outline: none;
  box-sizing: border-box;
}

html {
  font-family: var(--font-primary);
}

body {
  padding: 0;
  margin: 0;
}

body,
button,
input {
  font-family: var(--font-primary);
  font-size: 1em;
  background-color: white;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.1s ease-out;
}

/*
Disable elastic scrolling on macOS
https://stackoverflow.com/questions/8150191/disable-elastic-scrolling-in-safari/16327467#16327467
*/
html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
}
